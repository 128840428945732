import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box, Ram, ResponsiveContext, SupplierRegistrationForm } from '../ui';
import Layout from '../layout/primary';

const BackgroundBox = styled(Box)`
  background: linear-gradient(315deg, #b40000 0%, #e63d2f 100%);
`;
const PaddingBox = styled(Box)`
  padding: ${(props) => (props.size === 'small' ? '16px' : '94px')};
`;

export const query = graphql`
  {
    prismic {
      allSupplier_registrations {
        edges {
          node {
            meta_title
            meta_description
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.allSupplier_registrations.edges[0].node;

  return (
    <Layout title={content.meta_title} description={content.meta_description}>
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <BackgroundBox>
              <PaddingBox size={size} align="center">
                <Box width="996px" background="#FFFFFF" pad={{ vertical: 'large' }}>
                  <SupplierRegistrationForm
                    size={size}
                    regHeader="Supplier Registration"
                    regBlurb="Information provided is used to identify potential suppliers for contract opportunities. Registration does not guarantee any business enterprise the right to bid or receive a contract."
                    successHeading="Thank you for your message/registering"
                    successBody="Thank you! Your request has been successfully sent. We will contact you if partnership opportunities arise."
                    successButtonLabel="BACK TO HOMEPAGE"
                    successButtonTo="/"
                  />
                </Box>
              </PaddingBox>
              <Ram color="FFFFFF" />
            </BackgroundBox>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
